@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

@layer components {

    html,body{
        @apply flex flex-col flex-1 h-full;  
    }
    body{
        @apply flex flex-col flex-1;
    }

    /* layput */
    #root{
        @apply flex flex-col flex-1 h-full;
    }   
    .layout{
        @apply h-full;
    }
    .wrapper{
        @apply flex flex-col flex-1 h-full overflow-hidden relative z-30;
    }
    .main{
        @apply flex flex-col flex-1 w-full shrink-0 overflow-y-scroll;
    }
    .main > div{
        @apply overflow-y-auto first:h-full;
    }

    /* loading */
    .loading{
        @apply absolute top-0 left-0 right-0 bottom-0 bg-white bg-opacity-70 flex flex-row items-center justify-center p-4 z-50;
    }
    .loading .icon{
        @apply -mr-1 p-2 rounded-full bg-gray-100 shadow-lg relative z-20;
    }
    .loading .text{
        @apply text-xs text-gray-500 bg-white shadow-lg py-1 pl-5 pr-3 rounded-full -ml-3 relative z-10;
    }


    /* header */
    .header{
        @apply shadow-[0_-35px_60px_15px_rgba(0,0,0,0.3)] bg-white fixed top-0 left-0 right-0 z-50;
    }
    .header > div{
        @apply px-4 mx-auto flex justify-between items-center;
    }
    .header .logo{
        @apply text-xl border-b-4 border-b-purple-500 py-4;
    }


    /* footer */
    .footer{
        @apply hidden fixed bottom-0 left-0 right-0 bg-white text-gray-500 text-sm flex flex-row justify-between items-center shadow-[0_25px_30px_15px_rgba(0,0,0,0.3)] h-11;
    }
    .footer > div{
        @apply px-4;
    }
    .footer .ais-Pagination ul{
        @apply flex flex-row;
    }
    .footer .ais-Pagination ul > li > span,
    .footer .ais-Pagination ul > li > a{
        @apply block px-4 py-3 hover:bg-purple-200;
    }
    .footer .ais-Pagination ul > li > a.ais-Pagination-link--selected{
        @apply text-white bg-purple-500;
    }
    .footer .ais-Pagination ul > li.ais-Pagination-item--disabled{
        @apply hidden;
    }


    /* buttons */
    .button{
        @apply h-10 flex flex-row justify-center items-center px-3 transition ease-in-out;
    }
    .button > svg{
        @apply mr-1;
    }
    .button.primary{
        @apply bg-purple-500 hover:bg-purple-700 text-white font-light text-sm;
    }
    .button.black{
        @apply bg-black hover:bg-purple-700 text-white font-light text-sm outline-none h-auto py-2;
    }
    .button.outline{
        @apply bg-transparent hover:bg-purple-700 text-purple-500 hover:text-white font-light text-sm outline-none h-auto py-2 border-2 border-purple-500 hover:border-purple-700;
    }
    .button.gray{
        @apply bg-gray-200 hover:bg-purple-700 text-black font-light text-sm outline-none h-auto py-2 hover:text-white;
    }


    /* filter */
    .filter{
        @apply bg-black bg-opacity-75 pt-4 pb-5 px-4 flex flex-col lg:flex-row justify-between items-start gap-4 relative z-40 border-b-2 border-purple-500;
    }
    /* .filter > div{
        @apply w-full h-full justify-between;
    } */
    .filter > .mob{
        @apply w-full flex-col md:flex-row justify-between items-start gap-4 transition ease-in-out;
    }
    .filter > .mob > div{
        @apply w-full h-full justify-between;
    }
    .filter .logo{
        @apply text-xl text-white;
    }
    .filter .logo:after{
        content:''; 
        @apply block w-16 h-1 bg-purple-500;
    }
    .filter .menu{
        @apply absolute top-[50px] left-0 w-[200px] bg-white rounded-md z-[999999] px-0 py-3 overflow-hidden scale-y-0 transition-all duration-150 ease-in-out shadow-lg;
    }
    .filter .menu.active{
        @apply scale-y-100 overflow-visible;
    }
    .filter .menu:before{
        content:"";
        @apply border-[10px] border-b-gray-200 border-r-transparent border-l-transparent border-t-transparent absolute left-[4px] -top-[20px];
    }
    .filter .menu li{
        @apply border-b-[1px] border-b-gray-100 last:border-b-0;
    }
    .filter .menu li a{
        @apply text-sm font-light uppercase py-2 px-5 block bg-transparent hover:bg-gray-50;
    }



    /* form elements */
    .formGroup{
        @apply w-full relative;
    }
    .formGroup > label{
        @apply text-xs pb-1 opacity-80 text-white font-light;
    }
    .formGroup > div{
        @apply flex flex-row justify-between relative;
    }
    .formGroup .reset{
        @apply w-10 bg-purple-500 flex justify-center items-center rounded-sm;
    }
    .formGroup .multiSelect{
        @apply flex flex-col gap-2 bg-black rounded-sm p-3 overflow-y-scroll max-h-[13.3rem] h-[13.3rem] w-full text-white relative;
    }
    .formGroup .multiSelect > .subFilter{
        @apply sticky -top-3 -ml-3 -mr-3 -mt-3 z-50 p-1 bg-black;
    }
    .formGroup .multiSelect > .subFilter input{
        @apply text-xs rounded-sm px-2 h-7 text-white bg-white bg-opacity-40 outline-none w-full;
    }
    .formGroup .multiSelect > .subFilter button{
        @apply absolute top-2 right-2 z-50 mt-[1px];
    }
    .formGroup .item{
        @apply flex items-center text-sm;
    }
    .formGroup .item input{
        @apply absolute top-0 right-0 w-full h-full opacity-0 cursor-pointer;
    }
    .formGroup .item > div{
        @apply w-full ml-2 flex justify-between items-center text-xs;
    }
    .formGroup  .item > div > span{
        @apply ml-2 px-2 rounded-full bg-gray-500 text-xxs;
    }
    .formControl{
        @apply h-8 flex justify-start items-center px-2 text-white bg-black border-2 border-black outline-none focus:border-b-purple-500 rounded-sm w-full text-xs;
    }
    .formControl.light{
        @apply h-8 flex justify-start items-center px-2 text-black bg-white border-2 border-gray-200 outline-none focus:border-b-purple-500 rounded-sm w-full text-xs shadow-inner;
    }


    .switch{
        @apply relative;
    }
    .switch input{
        @apply absolute top-0 left-0 w-full h-full opacity-0 z-20 cursor-pointer;
    }
    .switch label{
        @apply text-white text-xs  flex justify-start items-start opacity-70 transition-all ease-in-out;
    }
    .switch label:before{
        content:'';
        @apply w-10 min-w-[40px] h-6 border-2 border-white rounded-full mr-3;  
    }
    .switch label:after{
        content:'';
        @apply w-4 h-4 bg-white absolute top-1 left-1 rounded-full transition-all ease-in-out;  
    }
    .switch label span{
        @apply py-[4px];
    }
    .switch input:checked + label:after{
        @apply left-5 bg-purple-500;
    }
    .switch input:checked + label:before{
        @apply border-purple-500;
    }
    .switch input:checked + label{
        @apply opacity-100;
    }
    

    /* modal */
    .modal{
        @apply fixed z-50 top-0 left-0 right-0 bottom-0 bg-white bg-opacity-50 flex justify-center items-center;
    }
    .modal > div{
        @apply bg-white shadow-lg pt-8 max-w-4xl rounded-md relative w-full;
    }
    .modal.small > div{
        @apply max-w-xs;
    }
    .modal.medium > div{
        @apply max-w-md;
    }
    .modal > div button.close{
        @apply absolute top-6 right-4 p-1 bg-gray-100 text-black rounded-lg flex justify-center items-center w-7 h-7 hover:bg-purple-500 hover:text-white;
    }
    .modal > div button.close i{
        @apply text-xs;
    }

    .modal .actions{
        @apply pl-8 pr-8 pb-8;
    }
    .modal .actions h3{
        @apply font-bold text-sm py-3;
    }
    .modal .actions ul{
        @apply flex flex-col;
    }
    .modal .actions ul > li{
        @apply block border-t-[1px];
    }
    .modal .actions ul > li > button{
        @apply w-full py-3 text-left text-sm hover:text-purple-500;
    }


    /* box */
    .box{
        @apply shadow-lg rounded-sm w-full my-4;
    }


    /* tabs */
    .tabs{
       
    }
    .tabs .tabsNav{
        @apply flex flex-1 flex-row;
    }
    .tabs .tabsNav  >button{
        @apply flex flex-1 justify-center items-center py-3 text-center border-t-[1px] border-b-[1px] border-l-[1px] first:border-l-0 border-gray-200 text-xs uppercase font-light hover:bg-purple-200 hover:border-purple-200 transition ease-in-out;
    }
    .tabs .tabsNav  >button.active{
        @apply bg-purple-500 text-white border-purple-500;
    }
    .tabs .tabContent{
        @apply text-sm;
    }


    /* login */
    .bgLogin{
        background:url('../../../public/bg-login.jpg');
        @apply bg-cover w-full h-full flex justify-center items-center;
    }

}


.onsales [class*='_TableHeadRow']{
   background: #eee !important;
}
.onsales [class*='_TableHead']{
   z-index: 9 !important;
}
.onsales [class*='_TableHeadRow'] > div:nth-child(2){
    position: sticky;
    left:0;
    z-index: 9 !important;
    background: #e0e0e0 !important;
}
.onsales [class*='_TableHeadRow'] > div:nth-child(5){
    position: sticky;
    left:50px;
    z-index: 9 !important;
    background: #e0e0e0 !important;
}
.onsales [class*='_TableHeadRow'] > div:nth-child(6){
    position: sticky;
    left:400px;
    z-index: 9 !important;
    background: #e0e0e0 !important;
}
.onsales [class*='_TableBody'] > div:hover > div{
    background:#eee;
}


.offsales [class*='_TableHeadRow']{
    background: #eee !important;
 }
 .offsales [class*='_TableHead']{
    z-index: 9 !important;
 }
 .offsales [class*='_TableHeadRow'] > div:nth-child(2){
     position: sticky;
     left:0;
     z-index: 9 !important;
     background: #e0e0e0 !important;
 }
 .offsales [class*='_TableHeadRow'] > div:nth-child(3){
     position: sticky;
     left:50px;
     z-index: 9 !important;
     background: #e0e0e0 !important;
 }
 .offsales [class*='_TableHeadRow'] > div:nth-child(4){
     position: sticky;
     left:400px;
     z-index: 9 !important;
     background: #e0e0e0 !important;
 }
 .offsales [class*='_TableHeadRow'] > div:nth-child(5){
     position: sticky;
     left:640px;
     z-index: 9 !important;
     background: #e0e0e0 !important;
 }
 .offsales [class*='_TableBody'] > div:hover > div{
     background:#eee;
 }


.DateRangePicker{
    @apply w-full;
}
.DateRangePickerInput__withBorder{
    @apply w-full flex justify-between items-center border-none;
}
.DateInput{
    @apply w-full bg-black;
}
.DateInput_input{
    @apply text-sm py-2 h-10 bg-black text-xs text-white py-1 h-8;
}

.CalendarDay__selected_span {
  @apply bg-purple-200;
}
.CalendarDay__selected {
  @apply bg-purple-500 border-purple-500;
}
.CalendarDay__selected:hover {
  @apply bg-purple-200 border-purple-300;
}
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  @apply bg-purple-200 border-purple-300 text-white;
}
.DayPickerKeyboardShortcuts_show__bottomRight::before{
    @apply border-r-purple-500;
}
.DayPickerKeyboardShortcuts_show__bottomRight:hover::before{
    @apply border-r-purple-700;
}
.DateInput_input__focused{
    @apply border-purple-500;
}
.DateRangePickerInput{
    @apply bg-black;
}



.slick-prev{
    left: 25px !important;
    z-index: 9;
}
.slick-next{
    right: 25px !important;
}